import renderHTML from 'react-render-html';
import React from 'react';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';

const HomeDesc = ({ desc, page, previousScreen }) => {
  const getStyle =
    page === 'home'
      ? css.resolve`
          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%);
            width: 450px;
            margin-left: -655px;
            font-family: 'MuseetSans';
            font-size: 26px;
            line-height: 1.35;
            margin-top: 60px;
            pointer-events: none;
          }
        `
      : css.resolve`
          div {
            font-family: 'MuseetSans';
            font-size: 26px;
            line-height: 1.35;
            max-width: 500px;
          }
        `;

  const { className, styles } = getStyle;
  const isPreviousCabinet =
    previousScreen === 'cabinet' || previousScreen === 'world';

  const variants =
    page === 'home'
      ? {
          initial: { x: 0, y: isPreviousCabinet ? -800 : 400 },
          enter: {
            y: '-50%',
            transition: {
              ...springAnim,
            },
          },
          exit: {
            y: -800,
            x: -200,
            opacity: 0,
            zIndex: 2,
            transition: {
              ...springAnim,
            },
          },
        }
      : {
          initial: { x: '-3%', opacity: 0 },
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              ...springAnim,
            },
          },
          exit: {
            x: '3%',
            opacity: 0,
            zIndex: 2,
            transition: {
              ...springAnim,
            },
          },
        };
  return (
    <motion.div
      className={className}
      initial='initial'
      animate='enter'
      exit='exit'
      variants={variants}
    >
      {renderHTML(desc)}
      {styles}
    </motion.div>
  );
};

export default HomeDesc;
