import React from 'react';
import renderHTML from 'react-render-html';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';
import DrawerButton from '../Buttons/DrawerButton';

const CabinetTuto = ({
  desc,
  mainColor,
  previousScreen,
  color,
  icon,
  iconWidth,
  isDrag,
  onZoom,
  zoomLevel,
  animate,
}) => {
  const getStyle = css.resolve`
    div {
      position: absolute;
      top: 50%;
      left: 100px;
      width: 350px;
      transform: translateY(-50%);
      font-family: 'MuseetSansBold';
      font-size: 22px;
      line-height: 1.8;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      pointer-events: none;
      margin-top: -100px;
      text-align: center;
    }
  `;

  const { className, styles } = getStyle;

  const xRight = 1550;
  const xLeft = -500;
  const isPreviousObject = previousScreen === 'object';
  const isPreviousHome = previousScreen === 'home';

  const defaultX = isPreviousObject ? xLeft : xRight;
  const outX = isPreviousObject || isPreviousHome ? 0 : 0;
  const outAlpha = isPreviousObject || isPreviousHome ? 0 : 0;
  const outY = 500;

  return (
    <motion.div
      className={className}
      initial='initial'
      animate='enter'
      exit='exit'
      variants={{
        initial: {
          x: isPreviousObject ? defaultX : 0,
          y: isPreviousObject ? '-50%' : outY,
          opacity: 0,
        },
        enter: {
          x: 0,
          y: '-50%',
          opacity: 1,
          transition: {
            // delay: delayTransition,
            ...springAnim,
          },
        },
        exit: (i) => ({
          opacity: outAlpha,
          y: i === 'exitY' ? outY : '-50%',
          x: i === 'exitX' ? outX : 0,
          transition: {
            ...springAnim,
          },
        }),
      }}
    >
      {styles}
      <div className={`key ${animate && 'animate'}`}>
        {animate ? (
          <DrawerButton url='instructions_70f_460x400.png' />
        ) : (
          <img src={icon} alt='' />
        )}
      </div>
      <div className='desc'>{renderHTML(desc)}</div>

      <style jsx>{`
        .key {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 30px auto;
          transform: translateX(0);
        }

        .animate {
          margin: 0 auto 20px auto;
        }
        img {
          width: ${iconWidth}px;
        }

        .desc {
          color: ${color};
        }
      `}</style>
    </motion.div>
  );
};

export default CabinetTuto;
