import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import css from 'styled-jsx/css';
import { delayTransition, springAnim } from '../../animationConfig';
import CabinetDrawer from './CabinetDrawer';

const CabinetSinglePoint = ({
  p,
  mainColor,
  onDrawer,
  hasBeenOpened,
  isAbsolute,
  i,
  drawerOn,
  pointClicked,
  hasDrawer,
}) => {
  const { pos } = p;

  /*if (!pos) {
    return null;
  }*/
  const { y, w, aId } = pos;

  const height = 63;

  const getStyle = css.resolve`
    div {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: ${mainColor};
      border: 0;
      padding: 0;
      margin: 0;
      outline: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }
  `;

  const { className, styles } = getStyle;

  const LargeIcon = (
    <motion.div
      className={className}
      initial='initial'
      animate='enter'
      exit='exit'
      variants={{
        initial: { scale: 0, opacity: 0 },
        enter: {
          opacity: 1,
          scale: 1,
          transition: {
            delay: delayTransition + aId * 0.1,
            ...springAnim,
          },
        },
        exit: {
          scale: 0,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      {styles}
    </motion.div>
  );

  const getStyleSmall = css.resolve`
    div {
      position: absolute;
      background: ${hasBeenOpened ? 'black' : 'white'};
      border-radius: 100%;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  `;

  const SmallIcon = (
    <motion.div
      className={getStyleSmall.className}
      initial='initial'
      animate='enter'
      exit='exit'
      variants={{
        initial: { scale: 0, x: '-50%', y: '-50%', opacity: 0 },
        enter: {
          scale: 1,
          opacity: 1,
          x: '-50%',
          y: '-50%',
          transition: {
            delay: delayTransition + 0.1 + aId * 0.1,
            ...springAnim,
          },
        },
        exit: {
          scale: 0,
          x: '-50%',
          y: '-50%',
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      {getStyleSmall.styles}
    </motion.div>
  );

  return (
    <div
      className={isAbsolute ? 'fixed' : 'container'}
      onClick={() => {
        onDrawer(p);
      }}
    >
      {hasDrawer && (
        <CabinetDrawer
          key={i}
          drawerOn={drawerOn}
          id={p.id}
          pointClicked={pointClicked}
        />
      )}
      <AnimatePresence exit='exit'>
        {pointClicked === -1 && (
          <motion.button
            whileTap={{ scale: 0.9 }}
            initial={{ scale: 1 }}
            enter={{ scale: 1 }}
            exit={{
              scale: 0,
              transition: {
                ...springAnim,
                duration: 0.5,
              },
            }}
            style={{
              willChange: 'transform',
              position: 'absolute',
              overflow: 'hidden',
              width: 50,
              height: 50,
              padding: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {LargeIcon}
            {SmallIcon}
          </motion.button>
        )}
      </AnimatePresence>

      <style jsx>{`
        .container {
          width: 100%;
          height: ${height}px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: ${y * height}px;
          pointer-events: all;
        }
        .fixed {
          width: 10px;
          height: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: ${w.x}px;
          top: ${w.y}px;
          pointer-events: all;
        }
      `}</style>
    </div>
  );
};

export default CabinetSinglePoint;
