import React from 'react';
import HomeTitle from './HomeTitle';
import HomeCabinet from './HomeCabinet';
import HomeDesc from './HomeDesc';
import HomeWorld from './HomeWorld';

import { AnimatePresence } from 'framer-motion';
import HomeMap from './HomeMap';

const Home = ({
  title,
  desc,
  mainColor,
  onWorld,
  isActivePage,
  previousScreen,
}) => {
  return (
    <AnimatePresence initial={'exit'}>
      {isActivePage && (
        <React.Fragment>
          <HomeMap previousScreen={previousScreen} />
          <HomeTitle
            key={1}
            title={title}
            page='home'
            mainColor={mainColor}
            previousScreen={previousScreen}
            animY={true}
          />
          <HomeCabinet key={2} previousScreen={previousScreen} />
          <HomeDesc
            desc={desc}
            key={3}
            page='home'
            previousScreen={previousScreen}
          />

          <HomeWorld
            key={5}
            mainColor={mainColor}
            onWorld={onWorld}
            previousScreen={previousScreen}
          />
        </React.Fragment>
      )}
    </AnimatePresence>
  );
};

export default Home;
