import React from 'react';
import { AnimatePresence } from 'framer-motion';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';
import WorldPoints from './WorldPoints';
import renderHTML from 'react-render-html';
import WorldTitle from '../WorldTitle/WorldTitle';
import DrawerButton from '../Buttons/DrawerButton';

const WorldPage = ({
  isActivePage,
  pointsPos,
  pointsData,
  mainColor,
  history,
  onDrawer,
  tutoDesc,
  title,
}) => {
  const getStyle = css.resolve`
    div {
      background: url(${require(`../../assets/world/world@2x.jpg`)});
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <AnimatePresence initial={'exit'}>
      {isActivePage && (
        <motion.div
          className={className}
          initial='exit'
          animate='enter'
          exit='exit'
          variants={{
            initial: {
              y: '100%',
            },
            enter: {
              y: 0,
              transition: {
                ...springAnim,
              },
            },
            exit: {
              y: '100%',
              transition: {
                ...springAnim,
              },
            },
          }}
        >
          <WorldTitle title={title} mainColor={mainColor} y={705} />
          <img
            className='path'
            src={`${require('../../assets/world/path@2x.png')}`}
            alt=''
          />
          <WorldPoints
            mainColor={mainColor}
            pointsPos={pointsPos}
            pointsData={pointsData}
            history={history}
            onDrawer={onDrawer}
          />
          <div className='tuto'>
            <DrawerButton url='cabinet_instruction_70f_460x400.png' />
            {renderHTML(tutoDesc)}
          </div>
          {styles}
          <style jsx>{`
            .path {
              position: absolute;
              top: 119px;
              left: 0;
              width: 100%;
              height: auto;
              z-index: 0;
            }
            .tuto {
              position: absolute;
              top: 40px;
              left: 50%;
              transform: translateX(-50%);
              font-size: 20px;
              letter-spacing: 0.1em;
              line-height: 1.8;
              text-transform: uppercase;
              text-align: center;
              color: white;
              font-family: 'MuseetSansBold';
            }
            .tuto span {
            }
          `}</style>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WorldPage;
