import React from 'react';
import CabinetSinglePoint from './CabinetSinglePoint';

import css from 'styled-jsx/css';

const CabinetPoints = ({
  pointsData,
  pointsPos,
  mainColor,
  onDrawer,
  history,
  drawerOn,
  pointClicked
}) => {
  pointsData.map((e, i) => {
    pointsPos.map((p, ip) => {
      if (i === ip) {
        e.pos = p;
      }
      return p;
    });
    return e;
  });

  const getPoint = (i, e) => (
    <CabinetSinglePoint
      key={i}
      i={i}
      mainColor={mainColor}
      p={e}
      onDrawer={onDrawer}
      hasBeenOpened={history.includes(e.id)}
      drawerOn={drawerOn}
      pointClicked={pointClicked}
      hasDrawer={true}
    />
  );

  const points1 = pointsData
    .filter(e => e.pos && e.pos.x === 0)
    .map((e, i) => getPoint(i, e));

  const points2 = pointsData
    .filter(e => e.pos && e.pos.x === 1)
    .map((e, i) => getPoint(i, e));

  const points3 = pointsData
    .filter(e => e.pos && e.pos.x === 2)
    .map((e, i) => getPoint(i, e));
  const points4 = pointsData
    .filter(e => e.pos && e.pos.x === 3)
    .map((e, i) => getPoint(i, e));

  const getStyle = css.resolve`
    div {
      position: absolute;
      bottom: 0;
      left: 14%;
      width: 73%;
      height: 62.5%;
      display: flex;
      z-index: 1;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <div className={className}>
      {styles}

      <div className='column'>{points1}</div>
      <div className='column'>{points2}</div>
      <div className='column'>{points3}</div>
      <div className='column'>{points4}</div>

      <style jsx>{`
        .column {
          width: 32%;
          position: relative;
        }
      `}</style>
    </div>
  );
};

export default CabinetPoints;
