import React, { useState } from 'react';
import CabinetObj from './CabinetObj';
import CabinetTuto from './CabinetTuto';
import { AnimatePresence } from 'framer-motion';

const Cabinet = ({
  mainColor,
  desc,
  points,
  pointsPos,
  onDrawer,
  history,
  isActivePage,
  previousScreen,
  screen,
  onEye,
}) => {
  let exit = screen === 'home' ? 'exitY' : 'exitX';
  exit = screen === 'cabinet-inside' ? 'exitNone' : exit;
  const [pointClicked, setPointClicked] = useState(-1);

  return (
    <React.Fragment>
      <AnimatePresence custom={exit}>
        {isActivePage && (
          <CabinetObj
            mainColor={mainColor}
            points={points}
            pointsPos={pointsPos}
            onDrawer={onDrawer}
            history={history}
            previousScreen={previousScreen}
            onEye={onEye}
            pointClicked={pointClicked}
            setPointClicked={setPointClicked}
          />
        )}
      </AnimatePresence>
      <AnimatePresence custom={exit}>
        {pointClicked === -1 && isActivePage && (
          <CabinetTuto
            desc={desc}
            mainColor={mainColor}
            previousScreen={previousScreen}
            color='black'
            icon={`${require('../../assets/cabinet/key.svg')}`}
            iconWidth={30}
            animate={true}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

export default Cabinet;
