import React from 'react';
import { motion } from 'framer-motion';
import css from 'styled-jsx/css';
import {
  staggerChildrenIn,
  delayChildren,
  springAnim,
} from '../../animationConfig';

import renderHTML from 'react-render-html';

const HomeTitle = ({ title, page, mainColor, previousScreen, animY }) => {
  const stylesTitle =
    page === 'home'
      ? css.resolve`
          div {
            position: absolute;
            top: 60px;
            text-align: center;
            width: 100%;
            color: #752c26;
            pointer-events: none;
          }
        `
      : css.resolve`
          div {
            margin-bottom: 40px;
            pointer-events: none;
          }
        `;
  /*
  const isPreviousCabinet =
    previousScreen === 'cabinet' ||
    previousScreen === 'world' ||
    previousScreen === 'object';*/

  const stylesItem = (i) => {
    return page === 'home'
      ? css.resolve`
          div {
            font-size: ${i === 0 ? `32px` : `60px`};
            text-transform: ${i === 0 ? `inherit` : `uppercase`};
            letter-spacing: ${i === 0 ? `13px` : `26px`};
            font-family: ${i === 0
              ? `MuseetSerifBoldItalic`
              : `MuseetSerifBold`};
            line-height: ${i === 0 ? `55px` : `65px`};
          }
        `
      : css.resolve`
          div {
            font-family: 'MuseetSerifBold';
            letter-spacing: 20px;
            font-size: 65px;
            line-height: 80px;
            color: ${mainColor};
            text-transform: uppercase;
          }
        `;
  };

  const titleLines = title.map((e, i) => {
    const { className, styles } = stylesItem(i);
    return (
      <div className='line' key={i}>
        <motion.div
          className={className}
          variants={{
            default: {
              y: '100%',
            },
            enter: {
              y: 0,
              transition: {
                ...springAnim,
              },
            },
            exit: {
              y: animY ? 0 : '100%',
              transition: {
                ...springAnim,
              },
            },
          }}
        >
          {renderHTML(e)}
          {styles}
        </motion.div>
        <style jsx>{`
          .line {
            overflow: hidden;
            min-height: 50px;
          }
        `}</style>
        <style jsx global>{`
          .line span {
            overflow: hidden;
            font-family: 'MuseetSerifBoldItalic';
            font-size: 35px;
          }
        `}</style>
      </div>
    );
  });

  const { className, styles } = stylesTitle;

  return (
    <motion.div
      className={className}
      variants={{
        enter: {
          y: 0,
          transition: {
            staggerChildren: staggerChildrenIn,
            delayChildren: delayChildren,
            ...springAnim,
          },
        },
        exit: {
          y: animY ? -900 : 0,
          opacity: 0,
          transition: {
            // staggerChildren: staggerChildrenOut,
            //staggerDirection: -1,
            ...springAnim,
          },
          zIndex: 2,
        },
        default: {
          y: animY ? -370 : 0,
          transition: {
            // staggerChildren: staggerChildrenOut,
            //staggerDirection: -1,
            ...springAnim,
          },
        },
      }}
      initial='default'
      animate='enter'
      exit='exit'
    >
      {titleLines} {styles}
    </motion.div>
  );
};

export default HomeTitle;
