import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import CabinetZoom from '../CabinetZoom/CabinetZoom';

const CabinetInsideDrag = ({ zoomLevel }) => {
  const defaultScale = 1.3;
  return (
    <div className='container'>
      <TransformWrapper
        defaultScale={defaultScale}
        defaultPositionX={-250}
        defaultPositionY={-400}
        options={{
          minScale: defaultScale,
          maxScale: 4,
          limitToBounds: true,
          limitToWrapper: true,
        }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <CabinetZoom
              zoomLevel={zoomLevel}
              onZoomIn={() => {
                zoomIn('click');
              }}
              onZoomOut={() => {
                zoomOut('click');
              }}
            />
            <TransformComponent>
              <img
                src={`${require('../../assets/cabinet/cabinet_inside.png')}`}
                alt=''
              />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      <style jsx>{`
        .container {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
        img {
          width: 100%;
          //max-height: 1500px; 
          object-fit: cover;
}
        }
      `}</style>
    </div>
  );
};

export default CabinetInsideDrag;
