import { UIButton } from 'oslo-ui';
import React from 'react';
import theme from '../../static/data/transverse_data.json';

const CabinetZoom = ({ onZoomIn, onZoomOut, zoomLevel }) => {
  return (
    <div className='container'>
      <React.Fragment>
        <UIButton
          type='zoom'
          background={theme.theme.main_color}
          color={theme.theme.second_color}
          click={onZoomOut}
          customIcon={require('../../assets/zoomOut.svg')}
          zindex={2}
          hidden={false}
        />

        <UIButton
          type='zoom'
          background={theme.theme.main_color}
          color={theme.theme.second_color}
          click={onZoomIn}
          blackLayer={true}
          customIcon={require('../../assets/zoomIn.svg')}
          zindex={1}
          hidden={false}
        />
      </React.Fragment>

      <style jsx>{`
        .container {
          position: absolute;
          text-align: center;
          z-index: 1;
          left: 0px;
          bottom: 0px;
        }
        button {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgb(117, 44, 38);
          background: white;
          border-radius: 100%;
          display: block;
          padding: 0;
          margin: 0;
          outline: 0;
          font-family: 'MuseetSansBold';
          font-size: 30px;
          margin-bottom: 15px;
          transition: 0.6s transform ease-out;
          pointer-events: all;
        }

        button:active {
          transform: scale3d(0.9, 0.9, 1);
        }

        span {
          margin-top: -4px;
          display: block;
        }
      `}</style>
    </div>
  );
};

export default CabinetZoom;
