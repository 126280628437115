const stiffness = 50;
const damping = 50;
const velocity = 0;
const staggerChildrenIn = 0.07;
const staggerChildrenOut = 0.05;
const delayChildren = 0.2;
const delayTransition = 0.7;
/*
const springAnim = {
  stiffness: stiffness,
  velocity: velocity,
  damping: damping
};*/

/** Quint / Quart */
const springAnim = {
  //ease: [0.86, 0.0, 0.07, 1.0],
  ease: [0.77, 0.0, 0.175, 1.0],
  duration: 1.5
};
export {
  stiffness,
  velocity,
  staggerChildrenIn,
  staggerChildrenOut,
  delayChildren,
  damping,
  springAnim,
  delayTransition
};
