import React from "react";
import { motion } from "framer-motion";
import { springAnim, delayTransition } from "../../animationConfig";
import css from "styled-jsx/css";

const UIAnimation = ({ button }) => {
  const getStyle = css.resolve`
    div {
      position: absolute;
      right: 0;
      bottom: 0;
      will-change: transform;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <motion.div
      className={className}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={{
        enter: {
          y: 0,
          transition: {
            delay: delayTransition,
            ...springAnim
          }
        },
        exit: {
          y: 160,
          zIndex: 2,
          transition: {
            ...springAnim
          }
        }
      }}
    >
      {button}
      {styles}
    </motion.div>
  );
};

export default UIAnimation;
