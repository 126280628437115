import React from 'react';
import { motion } from 'framer-motion';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';

const PageBackground = ({ url, withDelay, opacity }) => {
  const getStyle = css.resolve`
    div {
      background: url(${require(`../../assets/${url}`)});
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: -2;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <motion.div
      className={className}
      initial='exit'
      animate='enter'
      exit='exit'
      variants={{
        enter: {
          opacity: opacity ? opacity : 1,
          transition: {
            delay: withDelay ? withDelay : 0,
            ...springAnim,
          },
        },
        exit: {
          opacity: 0,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      {styles}
    </motion.div>
  );
};

export default PageBackground;
