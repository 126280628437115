import React from 'react';
import HomeTitle from '../Home/HomeTitle';
import ObjectSubTitle from './ObjectSubTitle';
import HomeDesc from '../Home/HomeDesc';
import css from 'styled-jsx/css';
import { motion } from 'framer-motion';
import { springAnim } from '../../animationConfig';

const ObjectInfos = ({ o, mainColor, previousScreen }) => {
  const title = o.name.split('<br>');

  const getStyle = css.resolve`
    div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 200px;
      width: 850px;
      margin-top: -20px;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <motion.div
      className={className}
      initial='default'
      animate='enter'
      exit='exit'
      variants={{
        default: {
          y: '-50%',
          x: 1450,
          transition: {
            //delay: delayTransition,
            ...springAnim,
          },
        },
        enter: {
          y: '-50%',
          x: 0,
          transition: {
            // delay: delayTransition,
            ...springAnim,
            delay: 1,
          },
        },
        exit: {
          y: '-50%',
          x: 1450,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      <HomeTitle
        title={title}
        page='object'
        mainColor={mainColor}
        previousScreen={previousScreen}
        animY={false}
      />
      <ObjectSubTitle loc={o.loc} date={o.date} mainColor={mainColor} />
      <HomeDesc desc={o.desc} page='object' />
      {styles}
    </motion.div>
  );
};

export default ObjectInfos;
