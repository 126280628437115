import React from 'react';
import { motion } from 'framer-motion';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';
const getStyle = css.resolve`
  div {
    background: url(${require(`../../assets/background/lignes.svg`)});
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    pointer-events: none;
    z-index: -2;
  }
`;
const PageBackgroundLines = ({ screen }) => {
  const { className, styles } = getStyle;

  /* const y = -380;
 const toY =
    screen === 'cabinet' || screen === 'cabinet-inside' || screen === 'object'
      ? y
      : 0;
  const toX = screen === 'object' ? -300 : -20;*/

  return (
    <motion.div
      className={className}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          ...springAnim,
        },
      }}
    >
      {styles}
    </motion.div>
  );
};

export default PageBackgroundLines;
