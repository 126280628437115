import React, { Component } from 'react';
import Spritesheet from 'react-responsive-spritesheet';

export default class CabinetDrawer extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.pointClicked.id !== prevProps.pointClicked.id &&
      this.props.pointClicked.id !== -1 &&
      this.props.pointClicked.id === this.props.id
    ) {
      this.spritesheeInstance.play();
    }
  }
  render() {
    const { id } = this.props;
    const cId = id + 1;
    return (
      <div className={`id-${cId}`}>
        <Spritesheet
          image={`${require(`../../assets/sprites/tiroir-${cId}_26f_450x300.png`)}`}
          widthFrame={450}
          heightFrame={300}
          steps={26}
          fps={30}
          direction={`forward`}
          autoplay={false}
          getInstance={(spritesheet) => {
            this.spritesheeInstance = spritesheet;
          }}
        />
        <style jsx>{`
          div {
            position: absolute;
            left: -160px;
            top: -104px;
            width: 450px;
            height: 300px;
            transform: scale(0.57, 0.57);
            pointer-events: none;
            // opacity: 0;
            // animation: 0.2s ease-in 1s fadeIn;
          }
          .id-2 {
            left: -150px;
            top: -96px;
          }
          .id-3 {
            top: -110px;
          }
          .id-4 {
            left: -135px;
            top: -100px;
          }
          .id-5 {
            left: -145px;
            top: -110px;
          }
          .id-6 {
            left: -145px;
            top: -98px;
          }
          .id-7 {
            top: -120px;
            left: -130px;
          }
          .id-8 {
            left: -130px;
            top: -110px;
          }
          /* @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }*/
        `}</style>
      </div>
    );
  }
}
