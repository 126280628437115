import React from 'react';

const BlockClicks = () => {
  return (
    <div>
      <style jsx>{`
        div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
        }
      `}</style>
    </div>
  );
};

export default BlockClicks;
