import React from 'react';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';

const HomeMap = ({ previousScreen }) => {
  const getStyle = css.resolve`
    div {
      position: absolute;
      right: 0;
      width: 630px;
      top: 0;
    }
  `;

  const isPreviousCabinet =
    previousScreen === 'cabinet' || previousScreen === 'world';
  const { className, styles } = getStyle;

  return (
    <motion.div
      className={className}
      initial='default'
      animate='enter'
      exit='exit'
      variants={{
        default: {
          y: isPreviousCabinet ? -750 : -750,
          x: 630,
          transition: {
            ...springAnim,
          },
        },
        enter: {
          y: 0,
          x: 0,
          transition: {
            ...springAnim,
          },
        },
        exit: {
          y: -750,
          x: 630,
          opacity: 0,
          zIndex: 2,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      <img
        className='map'
        src={`${require('../../assets/home/map.png')}`}
        alt=''
      />
      <style jsx>{`
        .map {
          width: 100%;
        }
      `}</style>
      {styles}
    </motion.div>
  );
};

export default HomeMap;
