import React from 'react';
import renderHTML from 'react-render-html';
import { motion } from 'framer-motion';
import { springAnim } from '../../animationConfig';
import css from 'styled-jsx/css';

const ObjectSubTitle = ({ loc, date, mainColor }) => {
  const getStyle = css.resolve`
    h2 {
      color: ${mainColor};
      font-size: 24px;
      line-height: 1.35;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-family: 'MuseetSerifHeavy';
      padding-left: 50px;
      background-image: url(${require('../../assets/objects/loc.svg')});
      background-repeat: no-repeat;
      background-size: auto 32px;
      background-position: 0 center;
      margin: 0;
      margin-bottom: 30px;
      max-width: 600px;
    }
  `;

  const { className, styles } = getStyle;
  return (
    <motion.h2
      className={className}
      initial='initial'
      animate='enter'
      exit='exit'
      variants={{
        initial: {
          opacity: 0,
          x: '-3%',
          // y: "-50%"
        },
        enter: {
          x: 0,
          // y: "-50%",
          opacity: 1,
          transition: {
            //  delay: delayTransition,
            ...springAnim,
          },
        },
        exit: {
          x: '3%',
          // y: "-50%",
          zIndex: 2,
          opacity: 0,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      {renderHTML(loc)}
      <span className='sep'> | </span>
      <span>{renderHTML(date)}</span>
      {styles}
      <style jsx>{`
        span {
          text-transform: initial;
          letter-spacing: 0;
          font-family: 'MuseetSerif';
          font-weight: normal;
          text-transform: initial;
        }
        .sep {
          margin-right: 4px;
        }
      `}</style>
    </motion.h2>
  );
};

export default ObjectSubTitle;
