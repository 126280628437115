import React from 'react';
import RoundButton from '../Buttons/RoundButton';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';
import css from 'styled-jsx/css';

const CabinetEye = ({ mainColor, onEye }) => {
  const getStyle = css.resolve`
    div {
      position: absolute;
      width: 110px;
      height: 110px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin-top: 35px;
    }
  `;

  const { className, styles } = getStyle;
  return (
    <motion.div
      className={className}
      whileTap={{ scale: 0.9 }}
      initial='exit'
      animate='enter'
      exit='exit'
      variants={{
        enter: {
          x: '-50%',
          y: '-50%',
          scale: 1,
          opacity: 1,
          transition: {
            delay: 0.8,
            ...springAnim,
          },
        },
        exit: {
          x: '-50%',
          y: '-50%',
          opacity: 0,
          scale: 0,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      <RoundButton mainColor={mainColor} onEye={onEye} />
      {styles}
    </motion.div>
  );
};

export default CabinetEye;
