import React from 'react';
import Spritesheet from 'react-responsive-spritesheet';

const DrawerButton = ({ url }) => {
  return (
    <button>
      <div className='inner'>
        <Spritesheet
          image={`${require(`../../assets/sprites/${url}`)}`}
          widthFrame={460}
          heightFrame={400}
          steps={70}
          fps={30}
          loop={true}
          direction={`forward`}
          autoplay={true}
          getInstance={(spritesheet) => {
            //this.spritesheeInstance = spritesheet;
          }}
        />
      </div>
      <style jsx>{`
        button {
          padding: 0;
          margin: 0;
          border: 0;
          outline: 0;

          background-size: 55px auto;
          background-position: center center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          pointer-events: all;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s transform ease-out;
        }

        .inner {
          width: 140px;
          height: 120px;
          transform-origin: center center;
        }
      `}</style>
    </button>
  );
};

export default DrawerButton;
