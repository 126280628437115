import React from 'react';
import Spritesheet from 'react-responsive-spritesheet';

const RoundButton = ({ mainColor, onEye }) => {
  return (
    <button onClick={onEye}>
      <div className='inner'>
        <Spritesheet
          image={`${require('../../assets/sprites/eye_2_75f_68x40.png')}`}
          widthFrame={68}
          heightFrame={40}
          steps={75}
          fps={30}
          loop={true}
          direction={`forward`}
          autoplay={true}
          getInstance={(spritesheet) => {
            //this.spritesheeInstance = spritesheet;
          }}
        />
      </div>
      <style jsx>{`
        button {
          padding: 0;
          margin: 0;
          border: 0;
          outline: 0;
          background-color: ${mainColor};
          background-size: 55px auto;
          background-position: center center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          pointer-events: all;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s transform ease-out;
        }
        button:active {
          transform: scale3d(0.97, 0.97, 1);
        }
        .inner {
          width: 68px;
          height: 40px;
          margin-left: 2px;
          transform-origin: center center;
        }
      `}</style>
    </button>
  );
};

export default RoundButton;
