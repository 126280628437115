import React from 'react';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';

const HomeCabinet = ({ previousScreen }) => {
  const getStyle = css.resolve`
    img {
      position: absolute;
      left: 50%;
      bottom: -200px;
      margin-left: -1000px;
      height: 860px;
      pointer-events: none;
    }
  `;

  const { className, styles } = getStyle;
  const isPreviousCabinet =
    previousScreen === 'cabinet' || previousScreen === 'world';

  return (
    <React.Fragment>
      <motion.img
        initial='default'
        animate='enter'
        exit='exit'
        className={className}
        src={`${require('../../assets/home/cabinet.png')}`}
        alt=''
        variants={{
          default: {
            y: isPreviousCabinet ? -1000 : 655,
            x: 0,
            transition: {
              ...springAnim,
            },
          },
          enter: {
            y: 0,
            x: 0,
            transition: {
              ...springAnim,
            },
          },
          exit: {
            y: -1000,
            opacity: 0,
            x: 0,
            zIndex: 2,
            transition: {
              ...springAnim,
            },
          },
        }}
      />

      {styles}
    </React.Fragment>
  );
};

export default HomeCabinet;
