import React from 'react';
import Page from '../../components/Page/Page';
import { OsloUI } from 'oslo-ui';
import { importTransAssets } from '../../utils/assetFetcher';

class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      lang: 'no',
      moduleData: undefined,
      transversalData: undefined,
    };
  }

  componentDidMount = () => {
    this.updateLang(this.state.lang);
  };

  isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
  };

  mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  };

  updateLang = (langCode) => {
    const transversalData = importTransAssets(
      this.mergeDeep(
        require(`../../static/data/${langCode}/transversal.json`),
        require(`../../static/data/transverse_data.json`)
      ),
      [
        ['home', 'background'],
        ['audioSubLang', 'backgroundImage'],
        ['informations', 'background'],
        ['credits', 'backgroundImage'],
        ['help', 'background'],
      ]
    );
    this.setState({
      lang: langCode,
      moduleData: {
        ...require(`../../static/data/${langCode}/data.json`),
        ...require(`../../static/data/module_data.json`),
      },
      transversalData,
    });
  };

  render = () => {
    const { lang, transversalData, moduleData } = this.state;

    if (moduleData === undefined || transversalData === undefined) {
      return null;
    }

    return (
      <div className='home'>
        <OsloUI
          Child={Page}
          moduleData={moduleData}
          data={transversalData}
          updateLang={this.updateLang}
          lang={lang}
        />

        <style global jsx>
          {`
            body {
              margin: 0;
              overflow: hidden;
              background: #9da8ab;
              touch-action: pan-x pan-y;
            }

            body::-webkit-scrollbar {
              width: 0 !important;
            }

            h1,
            h2,
            h3 {
              font-weight: normal;
            }
            button {
              margin: 0;
              padding: 0;
              font-weight: normal;
              background: none;
              box-shadow: 0;
              border: 0;
              outline: 0;
            }
          `}
        </style>
      </div>
    );
  };
}

export default App;
