import React from 'react';
import PageBackgroundMedia from '../PageBackground/PageBackgroundMedia';
import ObjectInfos from './ObjectInfos';
import ObjectMedia from './ObjectMedia';
import { AnimatePresence } from 'framer-motion';

const ObjectPage = ({ o, mainColor, isActivePage, previousScreen }) => {
  return (
    <AnimatePresence initial={'exit'}>
      {isActivePage && o && o.pos && (
        <React.Fragment>
          <PageBackgroundMedia
            key='object-back'
            url={`medias-back/${o.id}.png`}
            position={o.pos.bg.position}
            size={o.pos.bg.size}
          />
          <ObjectInfos
            key='object-infos'
            o={o}
            mainColor={mainColor}
            previousScreen={previousScreen}
          />

          <ObjectMedia
            key='object-media'
            id={o.id}
            height={o.pos.bg.height}
            marginRight={o.pos.bg.marginRight}
          />
        </React.Fragment>
      )}
    </AnimatePresence>
  );
};

export default ObjectPage;
