import React from 'react';
import { motion } from 'framer-motion';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';

const PageBackgroundMedia = ({ url, position, size }) => {
  const getStyle = css.resolve`
    div {
      background: url(${require(`../../assets/${url}`)});
      background-repeat: no-repeat;
      background-size: ${size ? size : '100% auto'};
      background-position: ${position ? position : 'center center'};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <motion.div
      className={className}
      initial='default'
      animate='enter'
      exit='exit'
      variants={{
        default: {
          x: '100%',
          opacity: 0.8,
        },
        enter: {
          x: 0,
          transition: {
            ...springAnim,
          },
        },
        exit: {
          x: '100%',
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      {styles}
    </motion.div>
  );
};

export default PageBackgroundMedia;
