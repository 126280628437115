import React from 'react';
import css from 'styled-jsx/css';
import RoundButton from '../Buttons/RoundButton';
import { springAnim } from '../../animationConfig';
import { motion } from 'framer-motion';

const HomeWorld = ({ mainColor, onWorld, previousScreen }) => {
  const getStyle = css.resolve`
    div {
      position: absolute;
      right: 0;
      width: 410px;
      top: 0;
    }
  `;

  const isPreviousCabinet =
    previousScreen === 'cabinet' || previousScreen === 'world';
  const { className, styles } = getStyle;

  return (
    <motion.div
      className={className}
      onClick={onWorld}
      initial='default'
      animate='enter'
      exit='exit'
      variants={{
        default: {
          y: isPreviousCabinet ? -400 : 950,
          transition: {
            ...springAnim,
          },
        },
        enter: {
          y: 300,
          transition: {
            ...springAnim,
          },
        },
        exit: {
          y: -400,
          x: 420,
          opacity: 0,
          zIndex: 2,
          transition: {
            ...springAnim,
          },
        },
      }}
    >
      <div className='eye'>
        <RoundButton mainColor={mainColor} />
      </div>

      <img
        className='back'
        src={`${require('../../assets/home/mappemonde.png')}`}
        alt=''
      />
      <style jsx>{`
        .eye {
          position: absolute;
          width: 110px;
          height: 110px;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          margin-left: -18px;
        }

        .back {
          width: 730px;
          margin-left: 0px;
        }
      `}</style>
      {styles}
    </motion.div>
  );
};

export default HomeWorld;
