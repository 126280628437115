import React from 'react';
import PageBackground from './PageBackground';
import PageBackgroundLines from './PageBackgroundLines';

const PageBackgrounds = ({ screen, opacity }) => {
  return (
    <React.Fragment>
      <PageBackground url={`background/bg.png`} opacity={opacity} />
      <PageBackgroundLines screen={screen} />

      <PageBackground url={`background/halo.png`} />
    </React.Fragment>
  );
};

export default PageBackgrounds;
