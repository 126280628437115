import React, { useState } from 'react';
import CabinetPoints from './CabinetPoints';
import CabinetEye from './CabinetEye';
import { motion, AnimatePresence } from 'framer-motion';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';

const CabinetObj = ({
  mainColor,
  points,
  pointsPos,
  onDrawer,
  history,
  previousScreen,
  onEye,
  pointClicked,
  setPointClicked,
}) => {
  const getStyle = css.resolve` 
  div {
    position: absolute;
    background-image: url("${require('../../assets/cabinet/cabinet_face.png')}");
    background-repeat: no-repeat;
    background-position: center 95px;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
`;

  const { className, styles } = getStyle;

  const [drawerOn, setDrawerOn] = useState(-1);

  const xRight = 1300;
  const xLeft = -1550;
  const isPreviousObject = previousScreen === 'object';

  const defaultX = isPreviousObject ? xLeft : 0;
  const outX = isPreviousObject ? xRight : xLeft;
  const outY = 800;

  const cabinetImg = (
    <motion.div
      className={className}
      initial='default'
      animate='enter'
      exit='exit'
      variants={{
        default: (i) => ({
          y: isPreviousObject ? 0 : outY,
          x: defaultX,
          transition: {
            // delay: delayTransition,
            ...springAnim,
          },
        }),
        enter: {
          y: 0,
          x: 0,
          transition: {
            // delay: delayTransition,
            ...springAnim,
          },
        },
        exit: (i) => ({
          y: i === 'exitY' ? outY : 0,
          x: i === 'exitX' ? outX : 0,
          transition: {
            ...springAnim,
          },
        }),
      }}
    >
      {styles}

      <React.Fragment>
        <AnimatePresence exit='exit'>
          {pointClicked === -1 && (
            <CabinetEye mainColor={mainColor} onEye={onEye} />
          )}
        </AnimatePresence>
        <CabinetPoints
          pointsData={points}
          pointsPos={pointsPos}
          mainColor={mainColor}
          history={history}
          drawerOn={drawerOn}
          pointClicked={pointClicked}
          onDrawer={(e) => {
            setPointClicked(e);
            setTimeout(() => {
              setDrawerOn(e.id);
              setTimeout(() => {
                onDrawer(e);
              }, 100);
            }, 1000);
          }}
        />
      </React.Fragment>
    </motion.div>
  );

  return (
    <div className='cabinet'>
      {cabinetImg}

      <style jsx>{`
        .cabinet {
          position: absolute;
          width: 55%;
          height: 100%;
          right: 10%;
          bottom: 0;
          // z-index: 2;
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

export default CabinetObj;
