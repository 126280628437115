import React, { useState, useEffect } from 'react';
import Home from '../Home/Home';
import Cabinet from '../Cabinet/Cabinet';
import ObjectPage from '../ObjectPage/ObjectPage';
import WorldPage from '../WorldPage/WorldPage';
import UIAnimation from '../UIAnimation/UIAnimation';
import { UIButton } from 'oslo-ui';
import PageBackgrounds from '../PageBackground/PageBackgrounds';
import CabinetInside from '../CabinetInside/CabinetInside';
import { easings } from 'oslo-ui';
import BlockClicks from '../BlockClicks/BlockClicks';
import HomeStartButton from '../Home/HomeStartButton';
const { easeInOutQuart } = easings;

let timeoutDestroyed;

const PageContent = ({
  moduleData,
  triggerRestart,
  homeOut,
  restart,
  hideCartelButtons,
  showCartelButtons,
}) => {
  const { home, cabinet, points, pointsPos, drag, world, worldTitle } =
    moduleData;
  const { title, desc, button } = home;
  const mainColor = '#752c26';

  const [screen, setScreen] = useState('home');
  const [animateIn, setAnimateIn] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [sceneVisible, setSceneVisible] = useState(false);
  //const [startButtonVIsible, setStartButtonVisible] = useState(false);
  const [object, setObject] = useState(null);
  const [history, setHistory] = useState([]);
  const [previousScreen, setPreviousScreen] = useState('home');
  const [cabinetDestoyed, setCabinetDestoyed] = useState(false);
  const [blockClicks, setBlockClicks] = useState(false);

  // Merge data infos and points
  points.map((e, i) => {
    pointsPos.map((p, ip) => {
      if (e.id === ip) {
        e.pos = p;
      }
      return p;
    });
    return e;
  });
  // Filter active obj
  const obj = points.filter((e) => e.id === object)[0];

  useEffect(() => {
    if (homeOut === true) {
      setAnimateIn(true);
      setAnimateOut(false);
      setSceneVisible(true);
    }

    if (homeOut === false) {
      setAnimateIn(false);
      setAnimateOut(true);
    }
  }, [homeOut]);

  useEffect(() => {
    if (triggerRestart === true) {
      restart();
    }
  }, [restart, showCartelButtons, triggerRestart]);

  const Button = (
    <UIButton
      type='close'
      color={'white'}
      background={mainColor}
      click={() => {
        let newPath = 'home';
        if (screen === 'object') {
          if (previousScreen === 'world') {
            newPath = 'world';
            clearTimeout(timeoutDestroyed);
          } else {
            newPath = 'cabinet';
            clearTimeout(timeoutDestroyed);
            setCabinetDestoyed(false);
          }
        }
        if (screen === 'cabinet-inside') {
          newPath = 'cabinet';

          showCartelButtons();
        }
        setPreviousScreen(screen);
        setScreen(newPath);
      }}
    />
  );

  return (
    <div
      className={`container ${animateIn ? 'in' : ''} ${
        animateOut ? 'out' : ''
      }`}
    >
      {blockClicks && <BlockClicks />}
      {sceneVisible && (
        <>
          <PageBackgrounds
            key={0}
            url={`background/bg.png`}
            screen={screen}
            opacity={0.7}
          />
          <Home
            title={title}
            desc={desc}
            mainColor={mainColor}
            previousScreen={previousScreen}
            isActivePage={screen === 'home'}
            onWorld={() => {
              setPreviousScreen(screen);
              setScreen('world');
            }}
          ></Home>

          {!cabinetDestoyed && (
            <Cabinet
              desc={cabinet.desc}
              mainColor={mainColor}
              points={points}
              pointsPos={pointsPos}
              history={history}
              previousScreen={previousScreen}
              screen={screen}
              isActivePage={screen === 'cabinet'}
              onDrawer={(p) => {
                setPreviousScreen(screen);
                setScreen('object');
                setBlockClicks(true);
                const idPoint = p.id;
                setObject(p.id);

                const newHistory = history;
                newHistory.push(idPoint);
                setHistory(newHistory);
                timeoutDestroyed = setTimeout(() => {
                  setCabinetDestoyed(true);
                  setBlockClicks(false);
                }, 1500);
              }}
              onEye={() => {
                setPreviousScreen(screen);
                setScreen('cabinet-inside');
                hideCartelButtons();
              }}
            />
          )}

          <ObjectPage
            o={obj}
            mainColor={mainColor}
            isActivePage={screen === 'object'}
            previousScreen={previousScreen}
          />

          <WorldPage
            mainColor={mainColor}
            isActivePage={screen === 'world'}
            pointsPos={pointsPos}
            pointsData={points}
            history={history}
            tutoDesc={world}
            onDrawer={(p) => {
              setPreviousScreen(screen);
              setScreen('object');
              const idPoint = p.id;
              //p = points[0];
              setObject(p.id);

              const newHistory = history;
              newHistory.push(idPoint);
              setHistory(newHistory);
            }}
            title={worldTitle}
            y={80}
          />

          <CabinetInside
            previousScreen={previousScreen}
            tutoDesc={drag}
            isActivePage={screen === 'cabinet-inside'}
            artwork={cabinet.artwork}
          />

          {screen !== 'home' && <UIAnimation button={Button}></UIAnimation>}
          <HomeStartButton
            text={button}
            mainColor={mainColor}
            onStart={() => {
              setPreviousScreen(screen);
              setScreen('cabinet');
            }}
            hidden={screen !== 'home'}
          />
        </>
      )}

      <style jsx>{`
        .container {
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // z-index: 0;
          transform: translate3d(0, 100%, 0);
          transition: transform 1.5s ${easeInOutQuart};
          z-index: -1;
        }

        .container.in {
          transform: translate3d(0, 0, 0);
        }

        .container.out {
          transform: translate3d(0, 100%, 0);
        }
      `}</style>
    </div>
  );
};

export default PageContent;
