import React from 'react';
import { UIButton } from 'oslo-ui';

const HomeStartButton = ({ text, mainColor, onStart, hidden }) => {
  return (
    <div>
      <UIButton
        type='navStart'
        color={'white'}
        background={mainColor}
        click={onStart}
        customIcon={`${require('../../assets/home/key.svg')}`}
        common={{ buttons: { navStart: { text: text } } }}
        hidden={hidden}
        iconWidth={25}
        iconHeight={'auto'}
        alignRight
      />
      <style jsx>{`
        div {
          z-index: 3;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default HomeStartButton;
