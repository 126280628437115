import React from 'react';
import { motion } from 'framer-motion';
import css from 'styled-jsx/css';
import { springAnim } from '../../animationConfig';

const ObjectMedia = ({ id, height, marginRight }) => {
  const getStyle = css.resolve`
    img {
      pointer-events: none;
      position: absolute;
      bottom: ${id === 5 ? 0 : 8}%;
      right: 0;
      height: ${height};
      margin-right: ${marginRight};
    }
  `;

  const { className, styles } = getStyle;

  return (
    <React.Fragment>
      <motion.img
        className={className}
        src={`${require(`../../assets/medias/${id}.png`)}`}
        alt=''
        initial='default'
        animate='enter'
        exit='exit'
        variants={{
          default: {
            x: 1200,
            opacity: 0,
          },
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              // delay: delayTransition,
              ...springAnim,
              delay: 1,
            },
          },
          exit: {
            x: 800,
            opacity: 0,
            zIndex: 2,
            transition: {
              ...springAnim,
            },
          },
        }}
      />
      {styles}
    </React.Fragment>
  );
};

export default ObjectMedia;
