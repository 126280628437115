import React, { useState, useEffect } from 'react';
import PageContent from './PageContent';

const Page = ({
  moduleData,
  triggerRestart,
  homeOut,
  restart,
  onAssetsLoaded,
  hideCartelButtons,
  showCartelButtons,
}) => {
  const [moduleVisible, setModuleVisible] = useState(false);

  useEffect(() => {
    if (homeOut) {
      setTimeout(() => {
        setModuleVisible(true);
      }, 10);
    }
  }, [homeOut]);

  useEffect(() => {
    //if (process.env.NODE_ENV !== 'production') {
    onAssetsLoaded();
    //}
    /*
    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', (event) => {
      onAssetsLoaded();
    });*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggerRestart === true) {
      restart();

      setTimeout(() => {
        setModuleVisible(false);
      }, 900);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRestart]);

  if (moduleVisible === false) return null;

  return (
    <PageContent
      moduleData={moduleData}
      triggerRestart={triggerRestart}
      homeOut={homeOut}
      restart={restart}
      hideCartelButtons={hideCartelButtons}
      showCartelButtons={showCartelButtons}
    />
  );
};

export default Page;
