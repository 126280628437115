import React from 'react';

const WorldTitle = ({ title, mainColor, y }) => {
  const lines = title.split('<br>').map((e, i) => (
    <div key={i}>
      <span></span>
      {e}
      <style jsx>{`
        div {
          font-size: 32px;
          line-height: 40px;
          color: white;
          text-transform: uppercase;
          text-align: center;
          font-family: 'MuseetSerifBold';
          letter-spacing: 14px;
          margin-bottom: 8px;
          position: relative;
          padding: 0px 5px;
          z-index: 1;
          display: inline-block;
        }
        span {
          position: absolute;
          top: 2px;
          left: -5px;
          width: 100%;
          height: 100%;
          background: ${mainColor};
          z-index: -1;
        }
      `}</style>
    </div>
  ));

  return (
    <div>
      {lines}
      <style jsx>{`
        div {
          position: absolute;
          top: ${y}px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default WorldTitle;
