import React, { useState } from 'react';
import CabinetTuto from '../Cabinet/CabinetTuto';
import CabinetInsideDrag from './CabinetInsideDrag';
import { AnimatePresence, motion } from 'framer-motion';
import { springAnim } from '../../animationConfig';

const CabinetInside = ({
  mainColor,
  previousScreen,
  tutoDesc,
  isActivePage,
  artwork,
}) => {
  const [zoomLevel, setZoomLevel] = useState(0);
  return (
    <AnimatePresence custom={'exitY'}>
      {isActivePage && (
        <div className='container'>
          <motion.div
            initial='initial'
            animate='enter'
            exit='exit'
            variants={{
              initial: {
                y: 1500,
              },
              enter: {
                y: 0,
                transition: {
                  ...springAnim,
                },
              },
              exit: () => ({
                y: 1500,
                transition: {
                  ...springAnim,
                },
              }),
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <CabinetInsideDrag zoomLevel={zoomLevel} />
          </motion.div>
          <CabinetTuto
            desc={tutoDesc}
            mainColor={mainColor}
            previousScreen={previousScreen}
            color='white'
            icon={`${require('../../assets/cabinet/drag.svg')}`}
            iconWidth={120}
            isDrag={true}
            zoomLevel={zoomLevel}
            onZoom={(z) => {
              let newZoomLevel;
              if (z === true) {
                newZoomLevel = 1;
              }
              if (z === false) {
                newZoomLevel = 0;
              }
              setZoomLevel(newZoomLevel);
            }}
          />
          <style jsx>{`
            .container {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 2;
            }
          `}</style>
        </div>
      )}
    </AnimatePresence>
  );
};

export default CabinetInside;
