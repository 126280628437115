import React from 'react';
import CabinetSinglePoint from '../Cabinet/CabinetSinglePoint';

const WorldPoints = ({
  mainColor,
  pointsData,
  pointsPos,
  history,
  onDrawer,
}) => {
  pointsData.map((e, i) => {
    pointsPos.map((p, ip) => {
      if (i === ip) {
        e.pos = p;
      }
      return p;
    });
    return e;
  });

  const points = pointsData.map((e, i) => (
    <CabinetSinglePoint
      key={i}
      i={i}
      mainColor={mainColor}
      p={e}
      onDrawer={onDrawer}
      hasBeenOpened={history.includes(e.id)}
      isAbsolute={true}
      hasDrawer={false}
      pointClicked={-1}
    />
  ));

  return <div>{points}</div>;
};

export default WorldPoints;
